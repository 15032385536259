import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';
import Head from '../shared/Head';
import aboutStyles from './about.module.scss';

const About = () => {
    return (
        <Layout>
             <Head title={`Despre`}/>
            <DoubleLayout>
                <h1 className={aboutStyles.pagetitle}>Despre</h1>
                <p>Well...ce pot spune despre acest blog?</p>
                <p>O sa fiu sincer..îl țin și îl scriu din cinci motive:</p>
                <ul className={aboutStyles.reasons}>
                    <li>ca să îmi țin mintea ascuțită în domeniu</li>
                    <li>ca să îmi promovez o platforma de cursuri despre programare și web development pe care urmeaza să o lansez
                    cândva în viitorul apropiat (dacă mai apuc vreodată să o termin că lucrez la ea de vreun de an)
                    </li>
                    <li>ca să vând cărți</li>
                    <li>pentru că îmi place să scriu despre acest domeniu că altfel aș face altceva</li>
                    <li>ca să ajut și să stau conectat cu oameni faini care vor să învețe ceva nou sau care vor să își schimbe cariera</li>
                </ul>
                <p>Cel mai probabil mai sunt și alte motive dar astea sunt cele mai principale.</p>
                <p>Aș fi putut scrie că fac asta pentru că sunt extrem de pasionat de acest domeniu și că la cât sunt de pasionat vreau
                    să îi ajut și pe alții dar nu ar fi fost 100% onest din partea mea. Da... sunt pasionat, vreau să ajut dar vreau să și vând o parte din lucrurile
                    menționate mai sus.
                </p>
                <p>Despre mine ce pot să spun?</p>
                <p>Sunt programator de șase ani, am experiență cu React, Angular, AngularJS și puțin cu VueJS. Mai știu și să fac teme de 
                    Wordpress dar nu am folosit asta niciodata la lucru. Pot spune că îmi place această meserie și îmi aduce satisfacții enorme.
                </p>
                <p>În viața de zi  cu zi sunt doar un tip banal...puțin nebun și puțin consiraționist
                     cum ar zice unii dar blogul asta nu e despre asta. Dacă vrei să citești teoria conspirației în varianta mea te invit să treci
                     și pe la blogul <span className={aboutStyles.bold}>nicolaealexandrupop.ro</span> (deocamdata nu e lansat oficial - conspiratiile sunt doar la mine pe calculator :)))</p>
                <p>Timpul liber mi-l ocup cu piticu, BJJ si JUDO - atunci când nu am ceva rupt in mine ... gen o mână.</p>
                <p>Timpul ocupat mi-l ocup cu munca :)) ce glume am. :))</p>
                <p>Dacă găsești acest conținut interesant atunci îți recomand să te abonezi la newsletter folosind 
                    oricare dintre formularele de prin zona și te voi stresa săptămanal iar când voi lansa platforma
                    de cursuri probabil că te voi stresa zilnic, din ora în ora....ca să-mi cumperi cursurile.</p>
            </DoubleLayout>
        </Layout>
    );
}
export default About;